<template>
  <v-row>
    <v-col cols="4">
      <v-list dense>
        <v-subheader>Themen</v-subheader>
        <v-list-item-group v-model="tag">
          <v-list-item
            v-for="item in tags"
            :key="item.id"
            :value="item"
            @change="editTag = { ...item }"
          >
            <v-list-item-icon>
              <v-icon>mdi-pound-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-btn fab color="primary" small class="ml-2" @click="editTag = {}"><v-icon>mdi-plus</v-icon></v-btn>
    </v-col>

    <v-col cols="8">
      <v-card v-if="editTag" flat>
        <!-- <v-card-title class="d-flex justify-space-between">
          <div>{{ editTag.id ? 'Thema bearbeiten' : 'Neues Thema erstellen' }}</div>
          <v-btn icon @click="editTag = null; tag = null"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title> -->
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="editTag.name" label="Name"/>
              <v-textarea v-model="editTag.desc" label="Beschreibung" hide-details/>
              <v-checkbox v-model="editTag.moderated" label="Moderiert" hide-details class="mb-6"/>
              <v-select
                v-if="editTag.moderated"
                v-model="editTag.moderators"
                :items="moderatorsOpts"
                label="Moderatoren"
                multiple
                dense
              />
              <v-checkbox v-model="editTag.protected" label="Schreibgeschützt"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn v-if="editTag.id" color="grey" text :loading="deleting" @click="delTag">Löschen</v-btn>
          <v-btn color="primary" text :loading="saving" @click="saveTag">Speichern</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TagsAdmin',
  data () {
    return {
      tag: null,
      editTag: null,
      saving: false,
      deleting: false
    }
  },
  computed: {
    tags () {
      return Object.entries(this.$root.tags).map(([id, tag]) => ({ id, ...tag }))
    },
    moderatorsOpts () {
      return Object.entries(this.$root.users)
        .map(([userId, user]) => ({ text: user.name, value: userId }))
        .sort((a, b) => a.text < b.text ? -1 : 1)
    }
  },
  methods: {
    subscribeTags () {
      if (this.unsubscribeTags) {
        this.unsubscribeTags()
      }

      this.unsubscribeTags = this.$fb.db.collection('tags')
        .orderBy('name')
        .onSnapshot(snap => {
          const tags = {}
          snap.forEach(doc => {
            const tag = doc.data()
            if (!tag._deleted) {
              tags[doc.id] = tag
            }
          })
          // update admin data
          this.$root.tags = { ...tags }
        })
    },
    async saveTag () {
      this.saving = true
      const saveTagFunc = this.$fb.fn.httpsCallable('saveTag')
      const response = await saveTagFunc({ ...this.editTag })
      if (response.data.ok) {
        this.editTag = null
        this.tag = null
      }
      this.saving = false
    },
    async delTag () {
      this.deleting = true
      const delTagFunc = this.$fb.fn.httpsCallable('delTag')
      const response = await delTagFunc({ id: this.editTag.id })
      if (response.data.ok) {
        this.editTag = null
        this.tag = null
      }
      this.deleting = false
    },
  },
  created () {
    this.subscribeTags()
  },
  beforeDestroy () {
    this.unsubscribeTags()
  }
}
</script>
