<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-list dense>
          <v-list-item-group v-model="view" class="mb-5">
            <v-list-item
              v-for="item in views"
              :key="item.id"
              :value="item"
            >
              <v-list-item-icon>
                <v-icon>{{ 'mdi-' + item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col cols="8">
        <v-card v-if="editView">
          <v-card-title class="d-flex justify-space-between">
            <div>{{ editView.name }}</div>
            <v-btn icon @click="editView = null; view = null"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text v-if="editView.type === 'posts' && !editView.collection">
            <!-- feed & tags -->
            <tags-admin/>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col>
                <v-checkbox v-model="editView.moderated" label="Moderiert" hide-details class="mb-6"/>
                <v-select
                  v-if="editView.moderated"
                  v-model="editView.moderators"
                  :items="moderatorsOpts"
                  label="Moderatoren"
                  multiple
                  chips
                />
                <v-checkbox v-model="editView.protected" label="Schreibgeschützt" hide-details/>
              </v-col>
              <v-col v-if="editView.type === 'posts' && editView.collection || editView.type === 'player'">
                <v-list dense class="py-0">
                  <v-subheader style="height: 26px">Kategorien</v-subheader>
                  <v-list-item
                    v-for="item in cats"
                    :key="item.id"
                    :value="item"
                    @click="editCat = { ...item }"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-view-quilt-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-btn fab color="primary" x-small class="ml-3 my-2" @click="editCat = {}"><v-icon>mdi-plus</v-icon></v-btn>
                </v-list>
              </v-col>
              <v-col v-if="editView.type === 'events'">
                <v-list dense class="py-0">
                  <v-subheader style="height: 26px">Kalender</v-subheader>
                  <v-list-item
                    v-for="item in cals"
                    :key="item.id"
                    :value="item"
                    @click="editCal = { ...item }"
                  >
                    <v-list-item-icon>
                      <v-icon :color="item.color">mdi-view-quilt-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-btn fab color="primary" x-small class="ml-3 my-2" @click="editCal = {}"><v-icon>mdi-plus</v-icon></v-btn>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="!(editView.type === 'posts' && !editView.collection)">
            <v-spacer/>
            <v-btn color="primary" text :loading="saving" @click="saveView">Speichern</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

        <v-dialog
          v-if="editCat !== null"
          :value="true"
          persistent
          width="400"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ editCat.id ? 'Kategorie bearbeiten' : 'Neue Kategorie erstellen' }}</v-toolbar-title>
              <v-spacer/>
              <v-btn icon dark @click="editCat = null">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-5">
              <v-text-field v-model="editCat.name" label="Name"/>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn v-if="editCat.id" color="grey" text :loading="deleting" @click="delCat">Löschen</v-btn>
              <v-btn color="primary" text :loading="saving" @click="saveCat">Speichern</v-btn>
              <v-spacer/>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-if="editCal !== null"
          :value="true"
          persistent
          width="400"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ editCal.id ? 'Kalender bearbeiten' : 'Neuen Kalender erstellen' }}</v-toolbar-title>
              <v-spacer/>
              <v-btn icon dark @click="editCal = null">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-5">
              <v-text-field v-model="editCal.name" label="Name"/>
              <div class="d-flex align-center justify-space-between">
                <v-select
                  v-model="editCal.color"
                  :items="colorOpts"
                  label="Farbe"
                  class="mr-2"
                />
                <v-icon :color="editCal.color">mdi-circle</v-icon>
              </div>
              <v-checkbox
                v-model="editCal.ical"
                label="Veröffentlichen (iCal)"
                :persistent-hint="editCal.ical"
                :hint="editCal.id ? ('iCal ID: ' + editCal.id) : ('Speichern, um iCal ID anzuzeigen')"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn v-if="editCal.id" color="grey" text :loading="deleting" @click="delCal">Löschen</v-btn>
              <v-btn color="primary" text :loading="saving" @click="saveCal">Speichern</v-btn>
              <v-spacer/>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TagsAdmin from '@/components/admin/TagsAdmin'

const CONFIGURABLE_VIEWS = ['posts', 'events', 'player']
const COLORS = ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey', 'shades']

export default {
  name: 'ViewsAdmin',
  components: { TagsAdmin },
  data () {
    return {
      views: null,
      view: null,
      editView: null,
      cats: null,
      editCat: null,
      cals: null,
      editCal: null,
      saving: false,
      deleting: false
    }
  },
  computed: {
    colorOpts () {
      return COLORS.map(c => ({ text: c, value: c }))
    },
    moderatorsOpts () {
      return Object.entries(this.$root.users)
        .filter(([userId, user]) => user.email && !user._disabled) // remove self, nologin and disabled
        .map(([userId, user]) => ({ text: user.name, value: userId }))
        .sort((a, b) => a.text < b.text ? -1 : 1)
    }
  },
  methods: {
    subscribeViews () {
      if (this.unsubscribeViews) {
        this.unsubscribeViews()
      }

      this.unsubscribeViews = this.$fb.db.collection('views')
        .onSnapshot(snap => {
          const views = {}
          snap.forEach(doc => {
            views[doc.id] = doc.data()
          })
          // based of $cfg views, but merged with admin view data
          this.views = this.$cfg.views
            .filter(v => CONFIGURABLE_VIEWS.includes(v.type))
            .map(v => ({
              ...v,
              ...views[v.id]
            }))
        })
    },
    async saveView () {
      this.saving = true
      const saveViewFunc = this.$fb.fn.httpsCallable('saveView')
      const response = await saveViewFunc({ ...this.editView })
      if (response.data.ok) {
        this.editView = null
        this.view = null
      }
      this.saving = false
      this.$root.reloadData()
    },
    async delView () {
      this.deleting = true
      const delViewFunc = this.$fb.fn.httpsCallable('delView')
      const response = await delViewFunc({ id: this.editView.id })
      if (response.data.ok) {
        this.editView = null
        this.view = null
      }
      this.deleting = false
      this.$root.reloadData()
    },
    subscribeCats () {
      if (this.unsubscribeCats) {
        this.unsubscribeCats()
      }

      this.unsubscribeCats = this.$fb.db.collection('views/' + this.editView.id + '/cats')
        .orderBy('name')
        .onSnapshot(snap => {
          const cats = []
          snap.forEach(doc => {
            const cat = { id: doc.id, ...doc.data() }
            if (!cat._deleted) {
              cats.push(cat)
            }
          })
          this.cats = cats
        })
    },
    subscribeCals () {
      if (this.unsubscribeCals) {
        this.unsubscribeCals()
      }

      this.unsubscribeCals = this.$fb.db.collection('cals')
        .orderBy('name')
        .onSnapshot(snap => {
          const cals = []
          snap.forEach(doc => {
            const cal = { id: doc.id, ...doc.data() }
            if (!cal._deleted) {
              cals.push(cal)
            }
          })
          this.cals = cals
        })
    },
    async saveCat () {
      this.saving = true
      const saveViewCatFunc = this.$fb.fn.httpsCallable('saveViewCat')
      const response = await saveViewCatFunc({ viewId: this.editView.id, ...this.editCat })
      if (response.data.ok) {
        this.editCat = null
      }
      this.saving = false
      this.$root.reloadData()
    },
    async delCat () {
      this.deleting = true
      const delViewCatFunc = this.$fb.fn.httpsCallable('delViewCat')
      const response = await delViewCatFunc({ viewId: this.editView.id, id: this.editCat.id })
      if (response.data.ok) {
        this.editCat = null
      }
      this.deleting = false
      this.$root.reloadData()
    },
    async saveCal () {
      this.saving = true
      const saveCalFunc = this.$fb.fn.httpsCallable('saveCal')
      const response = await saveCalFunc({ viewId: this.editView.id, ...this.editCal })
      if (response.data.ok) {
        this.editCal = null
      }
      this.saving = false
      this.$root.reloadData()
    },
    async delCal () {
      this.deleting = true
      const delCalFunc = this.$fb.fn.httpsCallable('delCal')
      const response = await delCalFunc({ viewId: this.editView.id, id: this.editCal.id })
      if (response.data.ok) {
        this.editCal = null
      }
      this.deleting = false
      this.$root.reloadData()
    },
    
  },
  watch: {
    view (view) {
      if (view) {
        this.editView = { ...view }
        if (this.editView.type === 'posts' && this.editView.collection || this.editView.type === 'player') {
          this.subscribeCats()
        } else {
          this.subscribeCals()
        }
      } else {
        if (this.unsubscribeCats) {
          this.unsubscribeCats()
        } else if (this.unsubscribeCals) {
          this.unsubscribeCals()
        }
      }
    }
  },
  created () {
    this.subscribeViews()
  },
  beforeDestroy () {
    this.unsubscribeViews()
    if (this.unsubscribeCats) {
      this.unsubscribeCats()
    }
    if (this.unsubscribeCals) {
      this.unsubscribeCals()
    }
  }
}
</script>
